/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "headline_colors_default": "text-black after:bg-primary-700",
  "headline_variant_default": "flex items-center whitespace-nowrap break-normal uppercase after:block",
  "headline_size_default": "text-headline-3xs after:h-[2px] after:w-full after:ml-3",
  "slide_caption_variant_default": "capitalize",
  "slide_caption_colors_default": "text-gray-600",
  "slide_caption_size_default": "text-headline-3xs",
  "slide_description_colors_default": "text-black",
  "slide_description_variant_default": "",
  "slide_description_size_default": "text-headline-2xs",
  "slide_group_variant_default": "[counter-reset:most-read] flex-nowrap flex-row flex overflow-x-auto overflow-y-hidden scrollbar-thin snap-x",
  "slide_group_colors_default": "scrollbar-thumb-primary-700",
  "slide_group_size_default": "gap-3 pb-3",
  "slide_image_variant_default": "relative after:absolute after:bottom-0 after:content-[counter(most-read)] after:flex after:items-center after:justify-center after:right-1.25 after:rounded-full after:translate-y-1/2",
  "slide_image_colors_primary": "after:bg-primary-700 after:text-white",
  "slide_image_size_default": "w-75 text-headline-xs after:h-9 after:w-9",
  "slide_variant_default": "[counter-increment:most-read] flex-col flex grow relative snap-center",
  "slide_colors_default": "",
  "slide_size_default": "gap-2.5",
  "colors_default": "bg-white text-black",
  "variant_default": "flex-col flex overflow-hidden",
  "size_default": "w-full gap-3"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "MostRead");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;